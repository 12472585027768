<template>
<div>
    <v-card>
        <v-tabs
            v-model="ui.tab"
            centered
            icons-and-text
        >
            <v-tabs-slider/>

            <v-tab href="#dashboard">
                Current Patients
                <v-icon>mdi-desktop-mac-dashboard</v-icon>
            </v-tab>

            <v-tab href="#newPatient">
                New Patient
                <v-icon>mdi-heart</v-icon>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="ui.tab">
            <v-tab-item
                value="dashboard"
            >
                <v-card flat>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="patients"
                            :items-per-page="25"
                            class="elevation-1"
                        ></v-data-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item
                value="newPatient"
            >
                <v-card flat>
                    <NewPatient v-if="ui.tab=='newPatient'" @cancel="ui.tab='dashboard'" @submit="ui.tab='dashboard'"/>
                </v-card>
            </v-tab-item>
        </v-tabs-items>


    </v-card>
</div>
</template>

<script>
import NewPatient from './newPatient.vue'
/*
Visit #1:  past date
Visit #2:  at least 4 months between visits
*/
export default {
    components: {
        NewPatient
    },
    data: function(){
        return {
            ui: {
                tab: 'dashboard'
            }
        }
    },
    computed: {
        headers: function(){
            return [
                {
                    text: 'Patient ID',
                    align: 'start',
                    value: 'patient_id',
                },
                { text: 'Onboarding Date', value: 'onboarding_date' },
                { text: 'Patient Assessment', value: 'patient_assessment' },
                { text: 'Visit #1', value: 'visit_1' },
                { text: 'Visit #2', value: 'visit_2' },
            ]
        },
        patients: function(){
            let output = []
            function newPatient(id){
                return {
                    patient_id: id,
                    onboarding_date: '2022-04-02',
                    visit_1: '2022-04-22',
                    visit_2: null,
                    assessments: []
                }
            }

            for(let i=1; i<=15; i++){
                output.push(newPatient(i))
            }

            return output
        }
    }
}
</script>

<style>

</style>