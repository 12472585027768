<template>
<div>
    <v-stepper v-if="state=='ready'" v-model="ui.step" :flat="true" max-width="1024" class="v-card--flat" style="margin: 0 auto;">
    <div v-if="debug">
        <v-btn
            color="primary"
            @click="ui.step++"
        >
            Next
        </v-btn>
        <v-btn v-if="ui.step>1" @click="ui.step--">
            Back
        </v-btn>                
    </div>
        <v-stepper-header>
            <v-stepper-step step="1" :complete="ui.stepState[1]" @click="ui.step=1" style="cursor:pointer;">
                Physician Consent
            </v-stepper-step>

            <v-stepper-step step="2" :complete="ui.stepState[2]">
                Practice Profile
            </v-stepper-step>

            <v-stepper-step step="3" :complete="ui.stepState[3]">
                Assessments
            </v-stepper-step>            
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <Consent :debug="debug" :consent_id="1" :state="modules.consent.state" :submissions="modules.consent.submissions" @submit="submitConsent"/>
                <center>
                    <v-btn
                        v-if="modules.consent.submissions.length>0"
                        color="primary"
                        @click="ui.stepState[2]!=true ? ui.step++ : ui.step=3"
                    >
                        Next
                    </v-btn>
                </center>                    
            </v-stepper-content>

            <v-stepper-content step="2">
                <Survey :questionSetID="modules.practiceProfile.questionSetID" :debug="debug" :questionSet="modules.practiceProfile.questionSet" @submit="submitPracticeProfile"/>
            </v-stepper-content>

            <v-stepper-content step="3">
                <Dashboard/>
                <!-- <v-btn
                    color="primary"
                    @click="$router.push({name:'Home'})"
                >
                    Continue
                </v-btn>
                <v-btn v-if="ui.step>1" @click="ui.step--">
                    Back
                </v-btn> -->
            </v-stepper-content>
        </v-stepper-items>


    </v-stepper>
    <h3 align='center' v-else>...Loading...</h3>
</div>
</template>

<script>
import Hashids from 'hashids'
import cache from '@/plugins/cache.js'
import Survey from '@/components/Questions/QuestionSet'
import Consent from '@/components/Consent/Consent'
import Dashboard from '@/components/PXP/Dashboard'

export default {
    components: {
        Consent,
        Survey,
        Dashboard
    },
    created: function(){
        this.init()
    },
    data: function(){
        return {
            state: 'waiting',
            ui: {
                step: 1,
                stepState: {
                    1: false,
                    2: false,
                    3: false
                }
            },
            form: {
                survey: {
                    consent: null,
                    practiceProfile: null
                }
            },
            modules: {
                consent: {
                    questionSetID: 'consent',
                    questionSet: null,
                    submissions: [],
                    state: 'idle'
                },
                practiceProfile: {
                    questionSetID: 'practiceProfile',
                    questionSet: null,
                    submissions: [],
                    state: 'idle'
                }
            }
        }
    },
    methods: {
        init: async function(){
            await this.getQuestions('practiceProfile').then(response=>{
                this.modules.practiceProfile.questionSet = response
            })
            await this.init_consent()
            await this.init_practice_profile()
            this.state = 'ready'
        },
        init_consent: async function(){
            this.modules.consent.state = 'fetching'
            await this.getSubmissions(this.$store.getters.userID, 'consent').then((response)=>{
                this.modules.consent.submissions = response
                for(let i=0; i<response.length; i++){
                    if(response[i].consent_id==1){
                        this.ui.step++
                        this.ui.stepState[1] = true
                    }
                }
                this.modules.consent.state = 'done'
            })
        },
        init_practice_profile: async function(){
            this.modules.practiceProfile.submissions = await this.getSubmissions(this.$store.getters.userID, 'practiceProfile').then((response)=>{
                if(response.length>0){
                    this.ui.step++
                    this.ui.stepState[2] = true
                }
            })

        },
        save: function save(user_id, key, data){
            let submissions = cache.storage.local.get(key) ? cache.storage.local.get(key) : {}
            if(!submissions[user_id]){
                submissions[user_id] = []
            }
            submissions[user_id].push(data)
            cache.storage.local.set(key,submissions)
        },
        submitConsent: function(data){
            this.form.survey.consent = data
            this.save(this.$store.getters.userID, 'consent', data)
            this.init_consent()
        },
        submitPracticeProfile: function(data){
            this.form.survey.practiceProfile = data
            this.save(this.$store.getters.userID, 'practiceProfile', data)

            this.ui.step++
            this.ui.stepState[2] = true
        },
        getQuestions: async function(questionSetID){
            function fetch(questionSetID){
                return new Promise((resolve, reject)=>{
                    setTimeout(function(){
                        let storage = window.localStorage;
                        let cache = storage.getItem('survey_editor_cache');
                        if(cache){
                            cache = JSON.parse(cache);
                            if(cache[questionSetID]){
                                resolve(cache[questionSetID])
                            }
                        }
                        reject()
                    },800)
                })
            }
            let response = await fetch(questionSetID)
            return response
        },
        getSubmissions: async function(user_id, key){
            function fetch(user_id, key){
                return new Promise((resolve, reject)=>{
                    setTimeout(function(){
                        let submissions = cache.storage.local.get(key) && cache.storage.local.get(key)[user_id] ? cache.storage.local.get(key)[user_id] : []
                        resolve(submissions)
                    },800)
                })
            }
            let response = await fetch(user_id, key)
            return response
        }
    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
    }
}
</script>

<style>

</style>