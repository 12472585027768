<template>
<div>
    <v-stepper v-model="ui.step" vertical>
        <v-stepper-step step="1" :complete="ui.stepState[1]">
            Patient Sign-Up
        </v-stepper-step>

            <v-stepper-content step="1">
                <v-card flat>
                    <v-text-field type="text" v-model="form.patientID" label="Patient Reference" dense outlined/>
                    <v-card-actions>
                        <v-btn
                            :disabled="form.patientID==null || form.patientID.length==0"
                            color="primary"
                            @click="ui.stepState[1]=true; ui.step++"
                        >
                            Continue
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-stepper-content>

        <v-stepper-step step="2" :complete="ui.stepState[2]">
            Patient Visit #1
        </v-stepper-step>

            <v-stepper-content step="2">
                <v-card flat>
                    <v-card-text style="text-align: center;">
                        <!-- :allowed-dates="allowedDates" -->
                        <!-- :min="today" -->
                        <v-date-picker
                        v-model="form.followUp"
                        class="mt-4"
                        />
                        
                    </v-card-text>
                </v-card>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :disabled="!form.followUp" @click="ui.step++;ui.stepState[2]=true">
                        Continue
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-stepper-content>            

        <v-stepper-step step="3" :complete="ui.stepState[3]">
            Patient Assessment for:  {{form.patientID}}
        </v-stepper-step>

            <v-stepper-content step="3">
                <v-card>
                    <Survey v-if="state=='ready'" :questionSetID="modules.newPatient.questionSetID" :debug="debug" :questionSet="modules.newPatient.questionSet" @submit="ui.step++;ui.stepState[3]=true"/>
                </v-card>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="$emit('cancel')">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" :disabled="!form.followUp" @click="$emit('submit')">
                        Save
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-stepper-content>



    </v-stepper>
    
</div>
</template>

<script>
import cache from '@/plugins/cache.js'
import Survey from '@/components/Questions/QuestionSet'

export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        Survey
    },
    mounted: function(){
        this.init()
    },
    data: function(){
        return {
            ui: {
                step: 1,
                stepState: {
                    1: false,
                    2: false,
                    3: false
                }
            },
            state: 'idle',
            modules: {
                newPatient: {
                    questionSetID: 'newPatient',
                    questionSet: null
                }
            },
            form: {
                followUp: null,
                patientData: null,
                patientID: null
            }
        }
    },
    methods: {
        init: async function(){
            await this.getQuestions('newPatient').then(response=>{
                this.modules.newPatient.questionSet = response
            })
            this.state = 'ready'
        },        
        getQuestions: async function(questionSetID){
            function fetch(questionSetID){
                return new Promise((resolve, reject)=>{
                    setTimeout(function(){
                        let storage = window.localStorage;
                        let cache = storage.getItem('survey_editor_cache');
                        if(cache){
                            cache = JSON.parse(cache);
                            if(cache[questionSetID]){
                                resolve(cache[questionSetID])
                            }
                        }
                        reject()
                    },800)
                })
            }
            let response = await fetch(questionSetID)
            return response
        },
        allowedDates: function(v){
            let today = new Date();
            let test = new Date(v)
            return test>=today
        }
    },
    computed: {
        today: function(){
            let today = new Date()
            let month = today.getMonth()<10 ? '0'+today.getMonth()+1 : today.getMonth()+1
            let date = today.getDate()<10 ? ''+today.getDate() : today.getDate()
            return today.getFullYear() + '-' + month + '-' + date
        }
    }
}
</script>

<style>

</style>